import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  title: "",
  firstName: "",
  lastName: "",
  phone: "",
  country: "",
}

function reducer(state, action) {
  switch (action.type) {
    case "SIGNUP": {
      return {
        ...state,
        title: action.payload.title,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        country: action.payload.country,
      }
    }
    case "PROCESS": {
      return {
        ...state,
        process: action.payload,
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
