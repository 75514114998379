// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-admin-gpm-1959-sg-tsx": () => import("./../../../src/pages/admin-gpm-1959-sg.tsx" /* webpackChunkName: "component---src-pages-admin-gpm-1959-sg-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-dashboard-edit-tsx": () => import("./../../../src/pages/dashboard/edit.tsx" /* webpackChunkName: "component---src-pages-dashboard-edit-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-gold-guide-tsx": () => import("./../../../src/pages/gold-guide.tsx" /* webpackChunkName: "component---src-pages-gold-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-register-set-password-tsx": () => import("./../../../src/pages/register/set-password.tsx" /* webpackChunkName: "component---src-pages-register-set-password-tsx" */),
  "component---src-pages-register-setup-diligence-form-tsx": () => import("./../../../src/pages/register/setup-diligence-form.tsx" /* webpackChunkName: "component---src-pages-register-setup-diligence-form-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blogIndex.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

