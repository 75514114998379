import React from "react";
import { DILIGENCE_FORMS } from "../utils/constants";

const initialState = {
  user: null,
  diligenceForm: null,

  documents: null,

  activeDiligenceFormTypeId: DILIGENCE_FORMS.INDIVIDUAL,
  userDocuments: [],
};

export const UserStateContext = React.createContext(initialState);
export const UserDispatchContext = React.createContext(obj => {});

function reducer(state, action) {
  switch (action.type) {
    case "set": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "reset":
      return initialState;
    default:
      throw new Error("Bad Action Type");
  }
}

const UserContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={state}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
};

export default UserContextProvider;
