/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import Proptypes from "prop-types";
import { Helmet } from "react-helmet";
import GlobalContextProvider from "./src/context/GlobalContextProvider";
import UserContextProvider from "./src/context/UserContextProvider";
import AuthContextProvider from "./src/context/AuthContextProvider";
import StepsContextProvider from "./src/context/StepsContextProvider";
import "antd/dist/antd.less";
import "./src/styles/global.scss";

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <script
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD5_gkxoGNBmmy0pxl--XrFj1atBxy7TSY&libraries=places"
          async
          defer
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-40173911-1"
        ></script>
      </Helmet>
      <GlobalContextProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <StepsContextProvider>{element}</StepsContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </GlobalContextProvider>
    </>
  );
};

wrapRootElement.propTypes = { element: Proptypes.any };
