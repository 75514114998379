const apiBaseUrl = process.env.GATSBY_API_BASE_URL;

export const ENDPOINTS = {
  SIGNUP: `${apiBaseUrl}/auth/signup`,
  LOGIN: `${apiBaseUrl}/auth/login`,
  SEND_VERIF_MAIL: `${apiBaseUrl}/auth/send-verif-mail`,
  USERS: `${apiBaseUrl}/users`,
  LIST_USERS: `${apiBaseUrl}/users/list`,
  DILIGENCE_FORM: `${apiBaseUrl}/diligence-form`,
  DILIGENCE_FORM_INDIVIDUAL: `${apiBaseUrl}/diligence-form/individual`,
  DOCUMENTS: `${apiBaseUrl}/documents`,
  VERIF_PHONE: `${apiBaseUrl}/users/verif-phone`,
  SET_PASSWORD: `${apiBaseUrl}/users/set-password`,
  CHECK_EMAIL: `${apiBaseUrl}/users/check-email-exitance`,
  CONTACT: `${apiBaseUrl}/contact`,
  BROCHURE: `${apiBaseUrl}/contact/brochure`,
};

export const TITLES = { MR: "mr", MRS: "mrs", DR: "dr" };

export const DILIGENCE_FORMS = {
  INDIVIDUAL: "individual",
  CORPORATE_ENTITY: "corporate_entity",
  TRUST_FOUNDATION: "trust_foundation",
};

export const DOCUMENT_TYPES = {
  ID: "id",
  ADDRESS_PROOF: "address_proof",
  INCORPORATION_CERTIFICATE_TRUST_DEED: "incorporation_certificate_trust_deed",
  PARTNERSHIP_AGREEMENT_OREQUI: "partnership_agreement_orequi",
  FOUNDATION_SHARTER: "foundation_sharter",
  TRUST_DEED: "trust_deed",
  INCORPORATION_CERTIFICATE: "incorporation_certificate",
  PASSPORT: "passport",
  // TODO: FINISH THE REST
};

export const ORIGIN_OF_ASSET = {
  GIFT_INHERITANCE: { KEY: "gift_inheritance", VALUE: "Gift/inheritance" },
  EARNING: { KEY: "earning_salary", VALUE: "Life time earnings/salary" },
  SALE_REAL_ESTATE: { KEY: "sale_real_estate", VALUE: "Sale of real estate" },
  INVESTMENTS: { KEY: "investments", VALUE: "Investments profits" },
  SALE_BUSINESS: { KEY: "sale_business", VALUE: "Sale of business" },
  LOTTERY: { KEY: "lottery", VALUE: "Winnings(lottery)" },
  OTHER: { KEY: "other", VALUE: "Others" },
};

export const ORIGIN_OF_ASSET_ORIGIN = {
  gift_inheritance: "From whom:",
  earning_salary: "Source:",
  sale_real_estate: "Properties:",
  investments: "Investments:",
  sale_business: "Campany:",
  lottery: "Source:",
  other: "Details:",
};

export const CORPORATE_ENTITY_TYPES = {
  LIMITED_COMPANY: { KEY: "limited_company", VALUE: "Limited Company" },
  PARTNERSHIP: { KEY: "partnership", VALUE: "Partnership" },
};

export const TRUST_FOUNDATION_TYPES = {
  FOUNDATION: { KEY: "foundation", VALUE: "Foundation" },
  TRUST: { KEY: "trust", VALUE: "Trust" },
};

export { default as COUNTRIES_LIST } from "./CountryList";

export const SHAREHOLDERS_STATUS = {
  YES: "yes",
  NO: "no",
  LATER: "later",
};

export const USER_STATUS = {
  VALID: "valid",
  PENDING: "pending",
};

export const COMPANY_ROLES = {
  DIRECTOR: "director",
  PARTNER: "partner",
  SHAREHOLDER: "shareholder",
  ULTIMATE_BENEFICIAL_OWNERS: "ultimate_beneficial_owners",
};

export const COMPANY_ROLES_LABELS = {
  director: "Director",
  partner: "Partner",
  shareholder: "Shareholder",
  ultimate_beneficial_owners: "Ultimate Beneficial Owners",
};
