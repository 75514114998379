import React from "react";

const initialState = {
  user: null,
  accessToken: null,
};

export const AuthStateContext = React.createContext(initialState);
export const AuthDispatchContext = React.createContext(obj => {});

function reducer(state, action) {
  switch (action.type) {
    case "set": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "reset":
      return initialState;
    default:
      throw new Error("Bad Action Type");
  }
}

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export default AuthContextProvider;
