import React from "react";

const initialState = {
  stepsNum: null,
  oldStep: -1,
  activeStep: 0,
};

export const StepsStateContext = React.createContext(initialState);
// eslint-disable-next-line no-unused-vars
export const StepsDispatchContext = React.createContext(it => {});

function reducer(state, action) {
  switch (action.type) {
    case "init": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "set": {
      return {
        ...state,
        oldStep: state.activeStep,
        activeStep: action.payload,
      };
    }
    case "inc": {
      return {
        ...state,
        oldStep: state.activeStep,
        activeStep: state.activeStep + 1,
      };
    }
    case "dec": {
      return {
        ...state,
        oldStep: state.activeStep,
        activeStep: state.activeStep - 1,
      };
    }
    case "reset":
      return initialState;
    default:
      throw new Error("Bad Action Type");
  }
}

const StepsContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <StepsDispatchContext.Provider value={dispatch}>
      <StepsStateContext.Provider value={state}>
        {children}
      </StepsStateContext.Provider>
    </StepsDispatchContext.Provider>
  );
};

export default StepsContextProvider;
